<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img class="size-full wp-image-23443 aligncenter" src="@/assets/news/16/1.png" alt="图片" width="816"  sizes="(max-width: 816px) 100vw, 816px"></p>
        <p style="text-align: center;"><span style="color: #0000ff;"><strong>再创历史，加拿大吸纳40.1万新移民</strong></span></p>
        <p>当地时间2021年12月23日，加拿大移民局官宣，今年已经实现年度目标吸收了401000新移民，这是加拿大移民历史上第二次达到40万新移民人数。</p>
        <p>与此同时，移民部长SEAN也表示：欢迎更多新移民。</p>
        <p><img class="size-full wp-image-23444 aligncenter" src="@/assets/news/16/2.png" alt="图片" width="798"  sizes="(max-width: 798px) 100vw, 798px"></p>
        <p>在疫情期间，加拿大从未停止接纳移民的步伐。</p>
        <p>疫情之前，2020年的移民目标是吸收34.1万新移民。然而由于疫情的影响2020年实际吸收的移民人数仅为18.4万。为了能够吸引足够多的新移民来激发加拿大市场活力，也为了在今年实现移民目标，加拿大展开了一些列“大动作”。</p>
        <p>给境内申请人开绿灯，在今年初，狂送PR身份，沸腾了整个留学圈子。经验类移民特快通道（EE CEC类别）池子内的27332名境内申请人一次性全体上岸，邀请分数更是创历史新低75分。5月份发布6个临时政策大赦项目转化9万留学生及工签持有者移民。11月创纪录的批准了47,434名申请人成为永久居民。</p>
        <p>在政府积极的推动下，加拿大的三年百万新移民计划，在21年底向大家上交了一份满分答卷。</p>
        <p>2022年加拿大新移民吸纳数量或将再增加。</p>
        <p>就如加拿大移民部部长弗雷泽所说，加拿大是以移民为基础的国家，移民使得加拿大更加美好，日后加拿大也将继续大力吸收移民，并与移民一起为创建加拿大而做出努力。</p>
        <p>在三年百万计划中，加拿大2022年的移民目标是41.1万新移民，也就是说，明年加拿大的移民目标比今年还要多。</p>
        <p><img class="size-full wp-image-23445 aligncenter" src="@/assets/news/16/3.jpg" alt="图片" width="800"  sizes="(max-width: 1080px) 100vw, 1080px"></p>
        <p>加拿大移民部长将会在2022年2月，宣布2022年的整体移民规划。</p>
        <p>值得强调的是，联邦移民部和各省都希望接收更多新移民。移民部也表示，期待在旅行限制措施解除之后接收更多的移民。同时，海外人士可以继续提交移民申请，那些已经获批的人士在疫情改善后，便可登陆加拿大。</p>
        <p>疫情期间，虽然不能自由通行对“百万移民计划”产生了影响，但由于加拿大移民局不断加强吸收新移民的力度，促使了政策的临时放宽，这对于一直观望加拿大的投资人来说确实是个难得的机会。</p>
        <p>通往加拿大<br>
          <img class="size-full wp-image-23446 aligncenter" src="@/assets/news/16/4.jpg" alt="图片" width="800"  sizes="(max-width: 1000px) 100vw, 1000px"></p>
        <p>萨省雇主担保移民</p>
        <p>萨省雇主担保移民项目优势</p>
        <p>一人申请，全家移民<br>
          无需商业背景、无需解释资金来源、无需面试</p>
        <p>对学历和语言要求低</p>
        <p>申请流程简单、省提名审批快</p>
        <p>可快速登陆加拿大</p>
        <p>萨省雇主担保移民项目申请条件</p>
        <p>学历要求：国内大专以上（建议）</p>
        <p>工作经验：申请前10年内有至少1年和提名职业相关的有效工作经验（必须）</p>
        <p><strong><span style="color: #0000ff;">其他要求：</span></strong></p>
        <p>1、申请人有萨省雇主聘用函，职业类别可以是NOC0/A/B或者指定行业且获得JAL；</p>
        <p>2、SINP评分达到60 分及以上；</p>
        <p>3、CLB4（雅思G类 听4.5 说4 读3.5 写4）</p>
        <p><span style="color: #0000ff;"><strong>安省雇主担保移民</strong></span></p>
        <p><strong>安省雇主担保移民项目优势</strong></p>
        <p>一人申请，全家移民</p>
        <p>无需投资，无需面试，全程在国内提交申请</p>
        <p>没有资产要求，无需解释资金来源</p>
        <p>EOI系统对学历和语言没有打分</p>
        <p>3-4个月获批省提名，可工签提前登陆安省，享受加拿大优质教育资源</p>
        <p><strong>安省雇主担保移民项目申请条件</strong></p>
        <p>针对人群：由资深经验的国内人士（建议50岁以下）</p>
        <p>学历要求：国内大专以上（建议）</p>
        <p>工作经验：申请前5年内有2年的有效工作经验（必须）</p>
        <p><span style="color: #0000ff;"><strong>其他要求：</strong></span></p>
        <p>1、申请人在获得一份符合职业类别NOC0/A/B的全职工作；</p>
        <p>2、工资符合同省份范围内该职位的普遍公司标准；</p>
        <p>3、申请前2年有效工作经验与目标职业的NOC类别相同；</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news16",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:16,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>